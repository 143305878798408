<template>
  <div style="padding-bottom: 32px;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="浙江金华" name="first">
        <jinhua-list></jinhua-list>
      </el-tab-pane>
      <el-tab-pane label="浙江丽水" name="second">
        <lishui-list></lishui-list>
      </el-tab-pane>
      <el-tab-pane label="浙江台州" name="three">
        <taizhou-list></taizhou-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LishuiList from "@/views/passport/zhejiang/LishuiList";
import JinhuaList from "@/views/passport/zhejiang/JinhuaList";
import TaizhouList from "@/views/passport/zhejiang/TaizhouList";

export default {
  components: { LishuiList, JinhuaList,TaizhouList },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {},
};
</script>

<style scoped></style>
